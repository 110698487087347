<template>
    <div class="login__container">
        <v-card class="login_form__container" max-width="500" min-height="550">
            <v-img
                src="../../assets/logoagro.png"
                max-height="100"
                max-width="100"
                alt="agrobofood logo"
                class="login_form__logo"
            >
            </v-img>
            <v-card-title>
                <v-row>
                    <v-col cols="12">
                        <h2 class="login_span text-center">
                            {{ $t("Login.title") }}
                        </h2>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-form class="login_form" @submit.prevent="login()">
                <v-card-text class="py-0">
                    <v-text-field
                        v-model="email"
                        :error-messages="emailErrors"
                        :label="$t('Login.fields.email')"
                        required
                        outlined
                        dense
                        @input="handleEmailInput()"
                        @blur="$v.email.$touch()"
                    ></v-text-field>

                    <v-text-field
                        v-model="password"
                        :append-icon="
                            showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="showPassword ? '' : 'password'"
                        :error-messages="passwordErrors"
                        :label="$t('Login.fields.password')"
                        required
                        outlined
                        dense
                        @input="handlePasswordInput"
                        @blur="$v.password.$touch()"
                        @click:append="showPassword = !showPassword"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="d-flex flex-column">
                    <v-alert
                        :value="showErrorAlert"
                        type="error"
                        dense
                        transition="slide-x-transition"
                    >
                        {{ errorAlertText }}
                    </v-alert>
                    <v-btn
                        type="submit"
                        class="text-button submit-button-hover"
                        color="primary"
                        depressed
                        :disabled="formNotValid"
                        :loading="loginStatus_Pending"
                    >
                        {{ $t("Login.fields.loginBtn") }}
                    </v-btn>

                    <v-container class="mt-5 pa-0 text-center">
                        <span> {{ $t("Login.forgotPassword") }} </span>
                        <router-link to="/forgot-password">
                            <span class="purple--text">
                                {{ " " + $t("Login.resetPass") }}
                            </span>
                        </router-link>
                    </v-container>
                    <router-link to="/register">
                        <p class="black--text text-center text--disabled mt-5">
                            {{ $t("Login.doesntHave") }}
                        </p>
                    </router-link>
                </v-card-actions>
            </v-form>
        </v-card>
    </div>
</template>

<script>
// API layer Imports
import { withAsync } from "../../helpers/withAsync";
import { loginUser } from "../../api/authApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import { apiObject } from "../../api/api";

// Vuelidate Imports
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
    name: "Login",

    mixins: [validationMixin],

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLengthValue: minLength(3),
        },
    },

    data() {
        return {
            email: "",
            password: "",
            showPassword: false,

            loginStatus: apiStatus.Idle,

            showErrorAlert: false,
            errorAlertText: "There was an error",
        };
    },

    computed: {
        ...apiStatusComputed("loginStatus"),

        /**
         * Following functions are used to validate the form on touch and blur
         * @summary Using vuelidate we can check for errors using vuelidate mixins and validations
         * @return {Array} returns array with errors to be shown under the text fields.
         */

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email &&
                errors.push(this.$t("ErrorMessages.validEmail"));
            !this.$v.email.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.minLengthValue &&
                errors.push(this.$tc("ErrorMessages.minLength", 3));
            !this.$v.password.required &&
                errors.push(this.$t("ErrorMessages.required"));
            return errors;
        },

        formNotValid() {
            return this.$v.$invalid;
        },
    },

    methods: {
        /**
         * Sends a call to the backend holding the needed data as payload.
         * If the credentials are valid it sets the Authorization header
         * to the access_token and dispatches 3 actions of the the vuex store.
         * The first sets the token in the global state and the localStorage.
         * The second sets the user_role in the global state and the localStorage.
         * The third sets the user_id in the global state and the localStorage.
         * At lat redirects the user to the home page
         */

        async login() {
            this.loginStatus = apiStatus.Pending;
            const payload = {
                email: this.email,
                password: this.password,
            };

            const { response, error } = await withAsync(loginUser, payload);

            if (error) {
                this.loginStatus = apiStatus.Error;
                this.showErrorAlert = true;
                if (error.response.status === 500) {
                    this.errorAlertText = error.response.statusText;
                    return;
                }
                this.errorAlertText =
                    error.response.data.message || error.response.data;
                return;
            }
            apiObject.defaults.headers[
                "Authorization"
            ] = `Bearer ${response.data.access_token}`;

            this.loginStatus = apiStatus.Success;

            const user_type = response.data.user_type.toString();
            const user_id = response.data.user_id;

            this.$store.dispatch("auth/setToken", response.data.access_token);
            this.$store.dispatch("auth/setUserRole", user_type);
            this.$store.dispatch("auth/setUserId", user_id);
            
            const cookieOptions = {
                token: response.data.access_token,
                domain: ".agrobofood.eu", //need to change with agrobofood subdomain to work with cross-domain cookie
                SameSite: "Strict",
                maxAge: "",
            };

            //Create cookie with wanted values
            document.cookie = `token=${cookieOptions.token};Domain=${cookieOptions.domain};SameSite=${cookieOptions.SameSite};`;
            // document.cookie= `name=${};`

            //If user is admin, redirect him in admin page on login,
            //else go to the home page

            if (user_type === "0") this.$router.push("/users");
            else this.$router.push("/home");
        },

        handlePasswordInput() {
            this.showErrorAlert = false;
            this.$v.password.$touch();
        },

        handleEmailInput() {
            this.showErrorAlert = false;
            this.$v.email.$touch();
        },
    },

    created() {
        this.apiStatus = apiStatus;
    },
};
</script>

<style scoped>
.login__container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.login_span {
    font-size: 1.3rem;
    transform: translateY(-30px);
}

.login_form__container {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 80%;
    height: 60%;
    margin: 50px;
}

.login_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_form__logo {
    transform: translate(-50px, -50px);
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}

@keyframes animateBackground {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.login__container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -2 !important;
    background: url("../../assets/pattern-circles.svg");
}

.login__container {
    background-image: linear-gradient(60deg, #42855b 0%, #e8e6d3 100%);
    background-size: 400% 400%;
    opacity: 0.8;
    animation: animateBackground 10s ease infinite;
}
</style>
