<template>
  <div>
      <v-app-bar
       app
       flat
       fixed
       height="75"
       width="100%"
       class="background"
      >
        <router-link :to="logoNavigation">
          <div class="hidden-sm-and-down">
            <v-img 
                src="../../assets/agrobofood_logo.svg"
                max-height="50"
                min-height="20"
                max-width="230"
                min-width="130"
                alt="agrobofood-logo"
            >
            </v-img>
          </div>
          <div class="hidden-md-and-up">
            <v-img 
            src="../../assets/logoagro_hor.png"
            min-height="10"
            max-width="150"
            alt="agrobofood-logo-mobile"
          >
          </v-img>
          </div>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-items
         class="hidden-md-and-down "
        >
              <v-btn
                  v-for="(user, index) in userMenu()"
                  :key="index"
                  color="black"
                  text
                  :to="user.path"
                  style="border: none;"
                  class="primary--text button-hover"
                >
                {{ user.title }}
                </v-btn>
            <admin-actions-menu
              v-if="userIsAdmin"
            >
            </admin-actions-menu>
             <v-btn
               color="black"
               text
               :href="forumURL"
               target="_blank"
               outlined
               style="border: none;"
               class="primary--text button-hover"
             >
              Forum
             </v-btn>
             <v-btn
                color="black"
                text
                :href="websiteURL"
                target="_blank"
                outlined
                style="border: none;"
                class="primary--text button-hover"
                >
                Public Site
             </v-btn>
         </v-toolbar-items>
         <search-input></search-input>
         <v-icon
          normal
          right
          color="black"
          class="hidden-lg-and-up"
          @click="sidebar = !sidebar"
         >
          {{ sidebar ? 'fa fa-times' : 'fa fa-bars' }}
         </v-icon>
         <v-btn
           v-if="userIsLogged && !userIsAdmin"
           color="black"
           height="40"
           width="40"
           fab
           depressed
           class="ml-5 profile_hover"
           :to="{ name: 'UserProfile' }"
         >
            <v-icon 
             small
             class="secondary--text"
            >
             fa fa-user
            </v-icon>
         </v-btn>
         <v-btn
          v-if="userIsAdmin"
          color="purple"
          height="40"
          width="40"
          fab
          depressed
          class="ml-5 logout_hover"
          @click="logout()"
         >
           <v-icon
            small
            class="secondary--text"
           >
            fas fa-right-from-bracket
           </v-icon>
         </v-btn>
        <v-navigation-drawer 
          v-model="sidebar"
          :disable-resize-watcher="true"
          absolute
          temporary
          hide-overlay
          left
          scrim="false"
          height="100vh"
          class="background-drawer"
        >
        <div class="d-flex flex-column">
        <div class="sidebar-logo-background">
        <v-img
          src="../../assets/agrobofood_logo.svg"
          max-height="55"
          min-height="30"
          max-width="180"
          min-width="130"
          class="mt-5 ml-5 align-self-center"
          alt="agrobofood-logo-navigation-drawer"
        >
        </v-img>
        </div>
        <!-- <v-divider class="my-3 primary"></v-divider> -->
        <v-list class="mt-3">
            <v-list-item
             v-for="item in userMenu()"
             :key="item.title"
             :to="item.path"
             class="navigation-icon secondary--text"
            >
             <v-icon
               small
               left
               class="secondary--text"
              >
               {{ item.icon }}
             </v-icon>
             <v-list-item-content>{{ item.title }}</v-list-item-content>
            </v-list-item>
            <v-list-item
             :href="websiteURL"
             target="_blank"
             class="navigation-icon secondary--text"
            >
            <v-icon
              small
              left
              class="secondary--text"
            >
               fa-solid fa-globe
            </v-icon>
            Public Site
            </v-list-item>
            <v-list-item
             :href="forumURL"
             target="_blank"
             class="navigation-icon secondary--text"
            >
             <v-icon
              small
              left
              class="secondary--text"
             >
              fa fa-comments
             </v-icon>
             Forum
            </v-list-item>
            <v-list-item v-if="userIsAdmin">
                <v-menu
                 offset-y
                 transition="slide-y-transition"
                 nudge-bottom="10"
                 min-width="140"
                >
                <template v-slot:activator="{ on, attrs }">
                    <div
                     class="navigation-icon"  
                     v-bind="attrs"
                     v-on="on"
                    >
                        <v-icon left normal color="purple">
                            {{ attrs["aria-expanded"] == "false" ? "fa fa-caret-down" : "fa fa-caret-up" }}
                        </v-icon>
                        <span class="secondary--text">{{ $t(`Admin.menu.label`) }}</span>
                    </div>
                </template>
                 <v-list dense>
                    <v-list-item
                     v-for="(item, index) in adminDropDown"
                     :key="index"
                     link
                     :to="item.path"
                     color="primary lighten-3"
                    >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                 </v-list>
                </v-menu>
            </v-list-item>
            <v-list-item
             v-if="userIsLogged && !userIsAdmin"
             :to="{ name: 'UserProfile'}"
             class="navigation-icon secondary--text"
            >
            <v-icon
              small
              left
              color="purple"
            >
               fa fa-user
            </v-icon>
              My Profile
            </v-list-item>
         </v-list>
         <v-img
          src="../../assets/logoagro.png"
          width="150"
          height="150"
          class="robot-image"
         ></v-img>
         <span class="copyrights secondary--text">Copyright &copy; {{ calculateCurrentYear }}<br>All Rights Reserved</span>
      </div>
    </v-navigation-drawer>
  </v-app-bar>
</div>
</template>

<script>
import { CONFIG }   from "@/common/config";
import { withAsync } from "../../helpers/withAsync.js";
import { logoutUser } from "../../api/authApi.js";
import { apiStatus } from "../../api/constants/apiStatus.js";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus.js";
import AdminActionsMenu from "@/components/AdminActionsMenu.vue";
import { mapGetters } from "vuex";
import SearchInput from '../../components/Inputs/SearchInput.vue';

export default {
    name: "NavigationHeader",
    components: {
        AdminActionsMenu,
        SearchInput,
    },
    data() {
        return {
            menuIsShown: false,
            userID: this.userId,
            sidebar: false,
            websiteURL: CONFIG.website_url,
            forumURL: CONFIG.forum_url,
            smeMenu: [
                { path: "/home", title: "Home", icon: "fa fa-home" },
                { path: "/public-profile", title: "Public Profile", icon: "fa fa-diagram-project" },
                // { path: "/maturity-tool", title: "Maturity Tool", icon: "fa fa-gear" },
                { path: "/my-solutions", title: "My Solutions", icon: "fa-thin fa-lightbulb" },
            ],
            dihMenu: [
                { path: "/home", title: "Home", icon: "fa fa-home" },
                { path: "/public-profile", title: "Public Profile", icon: "fa fa-diagram-project" },
                { path: "/toolbox", title: "Toolbox", icon: "fa fa-toolbox" },
                { path: "/maturity-tool", title: "Maturity Tool", icon: "fa fa-gear" },
            ],
            adminMenu: [
                { path: "/users", title: "Home", icon: "fa fa-home" },
                { path: "/toolbox", title: "Toolbox", icon: "fa fa-toolbox" }
            ],
            adminDropDown: [
                {
                    title: "Users list",
                    path: "/users",
                },

                {
                    title: "DIH listings",
                    path: "/dih",
                },

                {
                    title: "SME listings",
                    path: "/sme"
                },

                {
                    title: "Innovation demonstrators",
                    path: "/innovation-demonstrators"
                },

                {
                    title: "News",
                    path: "/news",
                },

                {
                    title: "Events",
                    path: "/events",
                },
                
                {
                    title: "Newsletter",
                    path: "/newsletter"
                },

                {
                    title: "Newsletter Subscriptions",
                    path: "/newsletter-subscriptions"
                }
            ]
        };
    },
    computed: {
        ...apiStatusComputed("logoutStatus"),
        ...mapGetters({
            userIsLogged: "auth/isLogged",
            userIsAdmin: "auth/userIsAdmin",
            determineUser: "auth/getUserRole",
            userId: "auth/getUserId",
        }),
        calculateCurrentYear() {
            return new Date().getFullYear();
        },
        logoNavigation() {
            if(this.userIsAdmin) {
                return '/users';
            } else {
                return '/';
            }
        }
    },
    methods: {
        userMenu() {
            if(this.determineUser == 0) {
                return this.adminMenu;
            }
            if(this.determineUser == 1) {
                return this.dihMenu;
            }
            if(this.determineUser == 2) {
                return this.smeMenu
            }
        },
        menuItems() {
            return this.menu;
        },
        /**
         * Handles the toggle of the side menu(shown only in mobile view)
         */
        toggleMenu() {
            if (this.menuIsShown) {
                this.menuIsShown = false;
            } else {
                this.menuIsShown = true;
            }
        },
        toggleIcon() {
            this.menuIsShown = !this.menuIsShown;
        },

        /**
         * Sends a call to the backend to logout the user.
         * On success it dispatches 2 vuex store actions (removes token and user role)
         * Then it replaces the current route with login route.
         */

        async logout() {
            this.logoutStatus = apiStatus.Pending;

            const { response, error } = await withAsync(logoutUser);

            if (error) {
                this.logoutStatus = apiStatus.Error;
                return;
            }

            this.logoutStatus = apiStatus.Success;
            this.$store.dispatch("auth/removeToken");
            this.$store.dispatch("auth/removeUserRole");
            this.$store.dispatch("auth/removeUserId");
            this.$router.replace("/login");
            document.cookie =
                "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
    },
};
</script>

<style scoped>
.background {
    background: #CFE7CD !important;
    /* background: linear-gradient(to right, #E8E6D3, #ffffff) !important; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    border-bottom: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #3b4c55, #ffffff);

}
.background-drawer {
    background: #3b4c55 !important;
    /* background: linear-gradient(to bottom, #3b4c55, #3b4c55cf) !important; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
.sidebar-logo-background {
    background: #CFE7CD;
    padding-bottom: 15px;
}
.button-hover {
    font-size: 1rem;
    font-weight: 400;
}
.button-hover:hover {
    border-bottom: 1px solid #33CC33 !important;
    background: #eee !important;
    color: #20262E !important;
    transition-duration: 0.3s ease-in-out;
}
.copyrights {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    text-align: center;
}
.robot-image {
    position: absolute;
    display: flex;
    margin-left: 40px;
    bottom: 3.5rem;
    left: 0;
    right: 0;
    opacity: 0.7;
}
.navigation-icon:hover {
    color: #C35272 !important;
}
.logout_hover:hover {
    background: #20262E !important;
    transition: .3s ease-in-out;
}
.profile_hover:hover {
    background: #C35272 !important;
    transition: .3s ease-in-out;
}
@media screen and (max-height: 630px) {
    .robot-image, .copyrights{
        display: none !important;
    }
}
</style>
